import React from "react";

import SEO from "../components/seo";
import Layout from "../components/layout";

import "../styles/layout.scss";

const PrivacyPolicy = () => (
  <Layout>
    <SEO title="Privacy Policy" />
    <div style={{ maxWidth: "1100px", margin: "50px auto", padding: "0 4%" }}>
      <h1>Privacy Policy</h1>
      <h2>Website visitors</h2>
      <p>
        Like most website operators, PG Treningslab collects non-personally-identifying information
        of the sort that web browsers and servers typically make available, such as the browser
        type, language preference, referring site, and the date and time of each visitor request. PG
        Treningslab’s purpose in collecting non-personally identifying information is to better
        understand how PG Treningslab’s visitors use its website. From time to time, PG Treningslab
        may release non-personally-identifying information in the aggregate, e.g., by publishing a
        report on trends in the usage of its website.
      </p>
      <p>
        PG Treningslab also collects potentially personally-identifying information like Internet
        Protocol (IP) addresses for logged in users and for users leaving comments on our blogs.
        Awesome Motive only discloses logged in user and commenter IP addresses under the same
        circumstances that it uses and discloses personally-identifying information as described
        below, except that blog commenter IP addresses are visible and disclosed to the
        administrators of the blog where the comment was left.
      </p>
      <h2>Gathering of Personally-Identifying Information</h2>
      <p>
        Certain visitors to PG Treningslab choose to interact with PG Treningslab in ways that
        require PG Treningslab to gather personally-identifying information. The amount and type of
        information that PG Treningslab gathers depends on the nature of the interaction. For
        example, we ask visitors who comment on our blog to provide a username and email address. PG
        Treningslab does not disclose personally-identifying information other than as described
        below. And visitors can always refuse to supply personally-identifying information, with the
        caveat that it may prevent them from engaging in certain website-related activities.
      </p>
      <h2>Cookies</h2>
      <p>
        A cookie is a string of information that a website stores on a visitor’s computer, and that
        the visitor’s browser provides to the website each time the visitor returns. PG Treningslab
        uses cookies to help PG Treningslab identify and track visitors, their usage of PG
        Treningslab website, and their website access preferences. PG Treningslab visitors who do
        not wish to have cookies placed on their computers should set their browsers to refuse
        cookies before using PG Treningslab, with the drawback that certain features of PG
        Treningslab may not function properly without the aid of cookies.
      </p>
      <h2>Comments</h2>
      <p>
        Comments and other content submitted to Akismet anti-spam service are not saved on our
        servers unless they were marked as false positives, in which case we store them long enough
        to use them to improve the service to avoid future false positives.
      </p>
      <h2>Privacy Policy Changes</h2>
      <p>
        Although most changes are likely to be minor, PG Treningslab may change its Privacy Policy
        from time to time, and in PG Treningslab sole discretion. PG Treningslab encourages visitors
        to frequently check this page for any changes to its Privacy Policy. Your continued use of
        this site after any change in this Privacy Policy will constitute your acceptance of such
        change.
      </p>
    </div>
  </Layout>
);

export default PrivacyPolicy;
